import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image";

import media from "@styles/media";
import colors from "@styles/colors";
import text from "@styles/text";

import useMedia from "@hooks/useMedia";

const PrimaryButton = loadable(() => import("@components/PrimaryButton"));
const TextAnimation = loadable(() => import("@components/TextAnimation"));

const Team: React.FC = () => {
  const [triggerTitle, setTriggerTitle] = useState(false);
  const [triggerText, setTriggerText] = useState(false);

  const wrapperRef = useRef(null);
  const imgRef = useRef(null);

  const tlStart = useMedia(
    "top-=40% top",
    "top-=40% top",
    "top-=80% top",
    "top-=40% top"
  );

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: tlStart,
      },
    });
    tl.to(imgRef.current, {
      duration: 0.8,
      height: "100%",
      ease: "circ.inOut",
    });
    tl.call(setTriggerTitle, [true], 0);
    tl.call(setTriggerText, [true], 0.2);

    const imgZoom = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top-=40% top",
        scrub: true,
      },
    });
    imgZoom.to(imgRef.current, {
      scale: "1.2",
    });

    return () => {
      tl.kill();
    };
    
  }, [imgRef, wrapperRef, tlStart, setTriggerTitle, setTriggerText]);

  return (
    <Wrapper ref={wrapperRef}>
      <Content>
        <Title>
          <TextAnimation
            textArray={[() => "About", () => "our team"]}
            className="about-team"
            height={useMedia("4.861vw", "4.861vw", "7.186vw", "16vw")}
            trigger={triggerTitle}
          />
        </Title>
        <P>
          <TextAnimation
            textArray={[
              () =>
                "We are a team of hard-working innovators, problem-solvers, and forward-thinkers. Over the years our team has demonstrated the ingenuity, grit, and collaboration that it takes to revolutionize the energy industry. From our engineering team to our client success team, our employees are mission-minded and focused on providing you with cutting-edge solutions.",
            ]}
            className="about-team-text"
            height={useMedia("14.444vw", "14.444vw", "20.120vw", "42.933vw")}
            trigger={triggerText}
          />
        </P>
    
        <PrimaryButton
          text="View Open Positions"
          backgroundColor={colors.black}
          textColor={colors.white}
          onClick={() => {
            window.open("https://www.linkedin.com/jobs/search/?f_C=10604931&geoId=92000000", "_blank")
          }}
          width={useMedia(
            "fit-content",
            "fit-content",
            "fit-content",
            "100%"
          )}
        />
        
      </Content>
      <BlackBlock />
      <ImageWrapper>
        <Img ref={imgRef}>
          <StaticImage
            src={'../../images/jpg/team.jpg'} 
            alt="mIQrotech team"
            placeholder="blurred"
            objectFit="cover"
            style={{width: '100%', height: '100%'}}
          />
        </Img>
      </ImageWrapper>
    </Wrapper>
  );
};

export default Team;

const Wrapper = styled.section`
  background-color: ${colors.culturedWhite40};
  position: relative;

  ${media.fullWidth} {
    padding-top: 12.431vw;
    padding-left: 11.319vw;
    padding-bottom: 12.292vw;
  }

  ${media.desktop} {
    padding-top: 12.431vw;
    padding-left: 11.319vw;
    padding-bottom: 12.292vw;
  }

  ${media.tablet} {
    padding-top: 16.168vw;
    padding-left: 5.988vw;
    padding-bottom: 19.88vw;
  }

  ${media.mobile} {
    padding-top: 133.067vw;
    padding-left: 6.667vw;
    padding-bottom: 47.733vw;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    text-decoration: none;
  }
  ${media.fullWidth} {
    width: 25.764vw;
  }

  ${media.desktop} {
    width: 25.764vw;
  }

  ${media.tablet} {
    width: 35.569vw;
  }

  ${media.mobile} {
    width: 86.933vw;
  }
`;

const Title = styled.h2`
  width: 100%;
  color: ${colors.black};
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    ${text.desktopLHeading}
    margin-bottom: 2.083vw;
  }

  ${media.desktop} {
    ${text.desktopLHeading}
    margin-bottom: 2.083vw;
  }

  ${media.tablet} {
    ${text.tabletMHeading}
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobileMHeading}
    margin-bottom: 5.333vw;
  }
`;

const P = styled.p`
  color: ${colors.black};
  width: 100%;
  display: flex;

  ${media.fullWidth} {
    ${text.desktopBodyCopy1}
    margin-bottom: 3.333vw;
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1}
    margin-bottom: 3.333vw;
  }

  ${media.tablet} {
    ${text.tabletSmallBody}
    margin-bottom: 4.790vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    margin-bottom: 10.667vw;
  }
`;

const BlackBlock = styled.div`
  background-color: ${colors.black};
  position: absolute;
  top: 0vw;
  right: 0vw;

  ${media.fullWidth} {
    width: 26.181vw;
    height: 37.361vw;
  }

  ${media.desktop} {
    width: 26.181vw;
    height: 37.361vw;
  }

  ${media.tablet} {
    width: 26.707vw;
    height: 40.24vw;
  }

  ${media.mobile} {
    width: 47.2vw;
    height: 81.333vw;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${media.fullWidth} {
    top: 11.042vw;
    right: 7.639vw;
    width: 48.333vw;
    height: 36.458vw;
  }

  ${media.desktop} {
    top: 11.042vw;
    right: 7.639vw;
    width: 48.333vw;
    height: 36.458vw;
  }

  ${media.tablet} {
    top: 15.808vw;
    right: 5.749vw;
    width: 47.665vw;
    height: 44.91vw;
  }

  ${media.mobile} {
    width: 86.667vw;
    height: 98.933vw;
    top: 27.467vw;
    right: 6.667vw;
  }
`;

const Img = styled.div`
  width: 100%;
  height: 0%;
`;
